@import "../../shared/styles/index";

.ModelDetails {
	position: relative;
	width: 100%;
	//max-width: $max-width-detail;
	margin: 0 auto;
	animation: fadeIn 0.55s ease-out;
	.ModelBio{
		@include font-usual(0.875, $color-black, 400, 1.5);
		padding: 0 2rem;
		text-align: left;
		@include desktop {
			padding: 0 13rem;
			margin: 3rem auto;
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
