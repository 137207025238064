@import "../../shared/styles/index";

.Features {
	position: relative;
	list-style: none;
	margin: auto 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	-webkit-overflow-scrolling: touch;
	scroll-snap-type: x mandatory;
	overflow-x: scroll;
	padding: 2rem 1rem;
	background-color: $color-white;
	box-sizing: border-box;
	z-index: 1;

	@include desktop {
		height: 5.625rem;
		padding: 2rem 1.25rem;
		margin: auto 0 3rem;
	}

	@include large-desktop {
		padding: 2rem;
		margin: auto 0 5rem;
	}

	@include xLarge-desktop {
		margin: auto 0 3rem;
	}

	&::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;

	.Features-item {
		margin: 0.25rem;
		text-transform: uppercase;
		@include font-usual(0.875, $color-black, 300);
		flex-shrink: 0;
		scroll-snap-align: center;

		@include desktop {
			font-size: 0.75rem;
		}

		@include large-desktop {
			font-size: 0.875rem;
		}

		span {
			&:nth-of-type(1) {
				margin: 0 .25rem;
				display: inline-block;
			}
		}

		strong {
			font-weight: 600;
		}

		&:last-child {
			padding: 0;
		}
	}
}
