@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin color-opacity($color, $opacity: 0.3) {
  color: rgba($color, $opacity);
}

@mixin border($top, $right, $bottom, $left, $type, $color) {
  border: $top $right $bottom $left $type $color;
}
