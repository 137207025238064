@import "../../shared/styles/index";

.PDFPortfolioButton {
	border: 0;
	box-sizing: border-box;
	cursor: pointer;
	@include font-usual(0.875, $color-white, 400);
	position: relative;
	text-transform: uppercase;
	transition: all 300ms;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-black;
	padding: 0;
	text-decoration: none;
	height: auto;
	background-color: transparent;
	animation: fadeIn 300ms;

	> span {
		display: inline-block;
		margin: 0 0.5rem 0 0;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

