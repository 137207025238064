@import url("https://use.typekit.net/phr4pwr.css");
@import "shared/styles/index";

body {
  margin: 0;
  padding: 0;
  background-color: $color-white;
	-webkit-overflow-scrolling: touch;
	touch-action: manipulation;

	&::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
}
