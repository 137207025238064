@import '../../shared/styles/index.scss';

.CoverGallery {
	position: relative;
	animation: fadeIn 1ms;
	width: 100%;
	//max-width: $max-width-content;
	margin: 0 auto;
	padding: 0 1.5rem;
	box-sizing: border-box;

	@include large-desktop {
		padding: 0 5rem;
	}

	.CoverGallery-logo {
		position: absolute;
		top: calc(40% - 2rem);
		left: calc(50% - 8.625rem);
		z-index: 10;

		@include desktop {
			top: calc(50% - 2rem);
			left: 4rem;
		}

		@include large-desktop {
			left: 8rem;
		}

		@include xLarge-desktop {
			left: 10rem;
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
