@import "../../shared/styles/index";

.Title {
	margin: 0;
	display: inline-block;
	@include font-ivymode(2, $color-black, 300, 3);

	@include tablet {
		font-size: 3rem;
	}

	&.Title--center {
		text-align: center;
	}

	&.Title--uppercase {
		text-transform: uppercase;
	}
}
