@import "../../shared/styles/index";

.InstagramCard {
	position: relative;
	margin: 0 1rem 5rem;
	transition: transform 250ms, opacity 300ms;
	text-decoration: none;
	width: 100%;

	&:last-child {
		margin: 0 1rem 1rem;
	}

	@include mobile {
		max-width: 22rem;
	}

	@include mobile {
		max-width: 33rem;
	}

	@include desktop {
		max-width: 28rem;

		&:nth-of-type(3n) {
			margin: 3rem 10rem 6rem;
		}

		&.InstagramCard--padded {
			padding: 12rem 0 0;
		}
	}

	.InstagramCard-image {
		background-color: $color-gray--dark;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		margin: 0 0 1.25rem;
		flex-shrink: 0;
		width: 100%;
		min-height: 25.625rem;
		display: block;

		@include tablet {
			min-height: 40rem;
		}

		@include large-desktop {
			margin: 0 0 2rem;
		}
	}

	.InstagramCard-time {
		@include font-usual(0.875, $color-black, 400);
		opacity: 0.3;
		margin: 0 0 0.5rem;
	}

	.InstagramCard-caption {
		@include font-usual(0.875, $color-black, 400, 1.75);
	}
}
