@import "../../shared/styles/index";

.Navigation {
	position: relative;
	display: flex;
	align-items: center;

	.Navigation-item {
		margin: 0 2rem 0 0;
		text-transform: uppercase;
		@include font-usual(0.75, $color-black, 400, 1, 0.5);
		flex-shrink: 0;
		text-decoration: none;
		transition: opacity 300ms;

		&:hover {
			opacity: 0.5;
		}

		&:last-child {
			margin: 0;
		}
	}
}
