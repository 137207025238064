@import "../../shared/styles/index";

.List {
	position: relative;
	list-style: none;
	margin: 0;
	display: flex;
	align-items: center;
	-webkit-overflow-scrolling: touch;
	height: 5rem;
	white-space: nowrap;
	width: 100%;

	&::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;

	.List-item {
		display: inline-block;
		text-decoration: none;
		margin: 0 1.5rem;
		flex-shrink: 0;
		text-transform: uppercase;
		@include font-usual(0.875, $color-black, 400);

		span {
			&:nth-of-type(1) {
				margin: 0 .25rem;
				display: inline-block;
			}
		}

		strong {
			font-weight: 600;
		}

		&:last-child {
			padding: 0 1rem 0 0;
		}
	}
}
