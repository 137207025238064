@import "../../shared/styles/index";

.Select {
	position: relative;
	width: 100%;

	@include tablet {
		max-width: 21.5rem;
	}

	@include desktop {
		max-width: 29.5rem;
	}

	@include large-desktop {
		max-width: 32rem;
	}

	&.Select--small {
		width: 48%;

		@include tablet {
			width: 100%;
		}

		@include large-desktop {
			max-width: 11.875rem;
		}
	}

	&.Select--medium {
		width: 48%;

		@include tablet {
			width: 100%;
		}

		@include large-desktop {
			max-width: 15.375rem;
		}
	}

	&.Select--large {
		max-width: 100%;
		transition: max-width 500ms ease;

		@include tablet {
			max-width: 21.5rem;
		}

		@include desktop {
			max-width: 49%;
		}

		@include large-desktop {
			max-width: 18.5rem;
		}
	}

	&.is-open {
		.Select-button {
			&:after {
				transform: rotate(180deg);
			}
		}
	}

	&.is-disabled {
		.Select-button {
			border-top-color: transparent;
			border-right-color: transparent;
			border-left-color: transparent;
			border-radius: 0;
			cursor: default;
		}
	}

	.Select-icon {
		position: absolute;
		right: 0.75rem;
		top: 1.5rem;
		transition: transform 300ms;
	}

	.Select-button {
		position: relative;
		border: 1px solid $color-black--pure;
		height: 3.375rem;
		width: 100%;
		box-sizing: border-box;
		transition: border-color 250ms;
		padding: 0 1rem;
		text-align: left;
		@include font-usual(0.875, $color-black, 400);
		text-transform: uppercase;
		background-color: $color-white;

		@include input-placeholder {
			@include font-usual(0.875, $color-black, 400);
			text-transform: uppercase;
		}

		&:focus {
			border-color: rgba(0, 0, 0, 0.65);
			box-shadow: none;
			outline: 0;
		}

		&.Select-button--withValue {
			text-transform: none;
		}

		&.is-open {
			.Select-icon {
				transform: rotate(180deg);
			}
		}
	}

	.Select-list {
		position: absolute;
		left: 0;
		box-sizing: border-box;
		background-color: $color-white;
		max-height: 0;
		z-index: 1;
		width: 100%;
		overflow-y: hidden;

		&.is-visible {
			border: 1px solid $color-black;
			border-top: none;
			max-height: 270px;
			overflow-y: auto;
			overflow-x: hidden;
			transition: max-height 0.3s ease-out;
		}
	}
}
