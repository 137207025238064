@import "../../../shared/styles/index";

.SelectItem {
  display: block;
  text-decoration: none;
  transition: all 250ms;
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 1rem;
  margin: 0 auto;
  width: 100%;
  text-align: left;
  z-index: 200;
	@include font-usual(0.875, $color-black, 400);

  &:hover {
	  background-color: $color-gray--light;
  }

	&:focus {
		outline: 0;
		box-shadow: none;
	}
}
