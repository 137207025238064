@import "../../shared/styles/index";

.Home {
	position: relative;

	.Home-wrapper {
		position: relative;
	}

	animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

