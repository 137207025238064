@import "../../shared/styles/index";

.WomanCard {
	position: relative;
	margin: 0 1.5rem 1.5rem 0;
	flex-shrink: 0;
	cursor: pointer;
	text-decoration: none;
	width: 46%;
	display: block;
	overflow: hidden;

	&:nth-of-type(2n) {
		margin: 0 0 1.5rem;
	}

	@include tablet {
		width: 47%;
		margin: 0 2.25rem 2rem 0;

		&:nth-of-type(2n) {
			margin: 0 0 2rem;
		}
	}

	@include desktop {
		width: 31%;
		margin: 0 1.75rem 2rem 0;

		&:nth-of-type(2n) {
			margin: 0 1.75rem 2rem 0;
		}

		&:nth-of-type(3n) {
			margin: 0 0 2.5rem;
		}
	}

	@include large-desktop {
		width: 17.75rem;
		margin: 0 1.5rem 2.5rem 0;

		&:nth-of-type(2n) {
			margin: 0 1.5rem 2.5rem 0;
		}

		&:nth-of-type(3n) {
			margin: 0 1.5rem 2.5rem 0;
		}

		&:nth-of-type(4n) {
			margin: 0 0 2.5rem;
		}
	}

	@include xLarge-desktop {
		width: 18.5rem;
		margin: 0 3rem 3rem 0;

		&:nth-of-type(2n) {
			margin: 0 3rem 3rem 0;
		}

		&:nth-of-type(3n) {
			margin: 0 3rem 3rem 0;
		}

		&:nth-of-type(4n) {
			margin: 0 0 3rem;
		}
	}

	.WomanCard-wrapper {
		height: 13.5rem;
		width: 100%;
		overflow: hidden;
		margin: 0 0 0.5rem;
		flex-shrink: 0;

		@include tablet {
			height: 26.5rem;
			margin: 0 0 1rem;
		}
	}

	.WomanCard-image {
		background-color: $color-gray--dark;
		background-size: cover;
		background-position: top center;
		height: 13.5rem;
		width: 100%;

		@include tablet {
			height: 26.5rem;
		}

		@include large-desktop {
			transition: opacity 300ms;
			//transform: scale(1.12);

			&:hover {
				height: 100%;
				//transform: scale(1);
				opacity: 0.7;
			}
		}
	}

	.WomanCard-content {
		@include font-ivymode(1, $color-black, 300, 1.25, 1);
		text-transform: uppercase;
	}
}
