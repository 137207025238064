@import "../../shared/styles/index";

.PrivacyPolicy {
	position: relative;
	max-width: $max-width-content;
	margin: 0 auto;

	.PrivacyPolicy-content {
		margin: 2rem auto;
		max-width: $max-width-form;
		padding: 0 2rem;

		@include large-desktop {
			padding: 0;
		}
	}

	.PrivacyPolicy-paragraph {
		margin: 0 0 3rem;

		> label {
			margin: 0 0 0.5rem;
		}

		> div {
			margin: 0.5rem 0 0;

			@include font-usual(0.75, $color-black, 400, 1.375);

			h4 {
				margin: 0.5rem 0 0.25rem;
			}

			ul {
				padding: 0;
				margin: 0 0 1rem 1rem;
				font-weight: 600;
			}
		}
	}
}

