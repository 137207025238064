@import "../../shared/styles/index";

.Paragraph {
  @include font-usual(0.75, $color-black, 400, 1.375);
  margin: 0;
  max-width: 21.5rem;

  @include desktop {
    max-width: 29.5rem;
  }

  @include large-desktop {
    max-width: 32rem;
  }
}
