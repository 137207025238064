@import '../../shared/styles/index.scss';

.Mosaic {
	position: relative;
	animation: fadeIn 600ms;
	width: 100%;
	max-width: $max-width-thumbnails;
	margin: 0 auto;
	box-sizing: border-box;
	height: 100%;
	overflow-y: auto;
	display: flex;
	align-items: center;

	@include fullHD-desktop {
		max-width: 90rem;
	}

	&.Mosaic--center {
		display: flex;
		justify-content: center;
		align-items: center;

		.Mosaic-content {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			height: auto;
		}
	}

	.Mosaic-content {
		position: relative;
	}
}

.Thumbnail {
	background-color: transparent;
	float: left;
	display: inline-block;
	padding: 0;
	margin: 0 .25rem;
	transition: opacity 250ms;
	cursor: pointer;
	border: 0;
	flex-shrink: 0;

	&:focus {
		outline: 0;
		box-shadow: none;
	}

	&:hover {
		opacity: 0.8;
	}

	.Thumbnail-image {
		height: 7.25rem;

		@include tablet {
			height: 7.125rem;
		}

		@include desktop {
			height: 7.75rem;
		}

		@include large-desktop {
			height: 7.5rem;
		}

		@include fullHD-desktop {
			height: 11rem;
		}

		width: auto;
	}

	&.Thumbnail--large {
		.Thumbnail-image {
			@include desktop {
				height: 8rem;
			}

			@include large-desktop {
				height: 8.5rem;
			}

			@include fullHD-desktop {
				height: 10rem;
			}
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
