@import "../../shared/styles/index";

.ModelNavigation {
	position: relative;
	display: flex;
	align-items: center;
	flex-flow: column;
	z-index: 1;

	@include desktop {
		flex-flow: row;
	}

	.ModelNavigation-items {
		display: flex;
		flex-flow: column;
		margin: 0 0 2rem;

		@include tablet {
			margin: 0 0 3.5rem;
		}

		@include desktop {
			margin: 0;
			flex-flow: row;
		}
	}

	.ModelNavigation-item {
		margin: 0 0 1.375rem;
		text-transform: uppercase;
		@include font-usual(1.125, $color-white, 500);
		flex-shrink: 0;
		text-decoration: none;
		transition: all 300ms;
		background-color: rgba(255, 255, 255, 0);
		padding: 0;
		border: 0;
		cursor: pointer;

		&:focus {
			outline: 0;
			box-shadow: none;
		}

		&:hover {
			opacity: 0.8;
		}

		&:last-child {
			margin: 0;
		}

		@include desktop {
			margin: 0 0.75rem 0;
		}
	}

	.ModelNavigation-title {
		@include font-usual(0.875, $color-black, 600);
		text-transform: uppercase;
		display: none;

		@include desktop {
			display: inline-block;
		}
	}

	.ModelNavigation-separator {
		height: 1px;
		width: 0.75rem;
		background-color: $color-black;
		margin: 0 0.875rem;
		display: none;

		@include desktop {
			display: inline-block;
		}
	}

	&.ModelNavigation--lightBox {
		z-index: 3;

		/*
		.ModelNavigation-title {
			font-size: 1rem;
		}*/

		.ModelNavigation-item {
			@include font-usual(1.125, $color-gray--dark, 400);
			margin: 0 0 1.75rem;

			@include tablet {
				margin: 0 0 1.25rem;
			}

			@include desktop {
				@include font-usual(0.875, $color-gray--dark, 400);
				margin: 0 0.35rem;

				&.is-active {
					text-decoration: underline;
					color: $color-black;
					font-weight: 600;
				}
			}
		}
	}

	.ModelNavigation-footer {
		display: flex;
		flex-flow: column;
		margin: auto auto 1rem;
		justify-content: center;
		align-items: center;

		@include desktop {
			display: none;
		}
	}

	.ModelNavigation-logo {
		max-width: 3rem;
		margin: 0 0 1rem;
	}

	.ModelNavigation-instagram {
		@include font-usual(0.75, $color-black, 400, 1, 0.5);
		text-transform: uppercase;
		text-decoration: none;
		transition: opacity 300ms;

		&:hover {
			opacity: 0.5;
		}
	}
}
.InstagramLink {
	position: relative;
	.InstagramLink-followers {
		opacity: 1;
		position: absolute;
		text-align: center;
		padding-top: 5px;
		color: #fff;
		width: 100%;
		font-size: .675rem;
		text-transform: uppercase;
		transition: all .3s;
		font-family: usual,Helvetica,Arial,sans-serif;
		transform: translateY(-15px);
		strong {
			font-weight: 600;
		}
		@include desktop {
			opacity: 0;
			transform: translateY(10px);
		}
	}
	&:hover {
		.InstagramLink-followers {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
