@import "../../shared/styles/index";

.Contact {
	position: relative;
	animation: fadeIn 0.55s ease-out;

	.Contact-wrapper {
		padding: 2rem;
		max-width: $max-width-content;
		margin: 0 auto;
		min-height: 50rem;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

