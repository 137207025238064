@import "../../shared/styles/index";

.Label {
	margin: 0;
	display: inline-block;
	@include font-usual(0.875, $color-black, 600);
	text-transform: uppercase;

	&.Label--light {
		font-weight: 400;
	}
}
