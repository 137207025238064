@import "../../shared/styles/index";

.ScoutingForm {
	position: relative;
	max-width: $max-width-form;
	margin: 0 auto;
	padding: 0 2rem;

	@include large-desktop {
		padding: 0;
	}

	.ScoutingForm-header {
		margin: 4rem auto;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;

		> img {
			width: 2.5rem;
			height: auto;
			margin: 0 0 2.5rem;
		}
	}

	.ScoutingForm-subtitle {
		margin: 0 0 5rem;
		text-align: center;
		@include font-usual(0.875, $color-black, 400, 1.5);

		@include desktop {
			margin: 0 0 10rem;
		}
	}

	.ScoutingForm-tips {
		margin: 0 0 5rem;
		> div {
			margin: 2rem 0;

			> div {
				margin: 0 0 2rem;
			}

			@include tablet {
				display: flex;
				justify-content: space-between;

				> div {
					margin: 0;
				}
			}
		}

		@include large-desktop {
			margin: 0 0 10rem;
		}
	}

	.ScoutingForm-content {
		position: relative;
		min-height: 33rem;
		margin: 2.75rem auto;
		max-width: $max-width-form;

		.ScoutingForm-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			> div {
				margin: 0 0 1rem;
			}
		}

		.ScoutingForm-unit {
			margin: 3rem 0;

			@include tablet {
				margin: 4rem 0 3rem;
			}
		}

		.ScoutingForm-measurements {
			margin: 3rem 0 5rem;

			@include large-desktop {
				margin: 6rem 0 10rem;
			}
		}

		.ScoutingForm-boxes {
			margin: 3rem 0 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;

			> div {
				margin: 0 0 2rem;
			}

			@include large-desktop {
				margin: 4rem 0 0;

				> div {
					margin: 0;
				}
			}
		}

		.ScoutingForm-upload {
			margin: 2rem 0;
		}
	}

	.ScoutingForm-disclaimers {
		margin: 4.5rem 0;
	}

	.ScoutingForm-required {
		@include font-usual(0.875, $color-black, 600);
		display: inline-block;
		margin: 1.25rem 0 0;
	}

	.ScoutingForm-video {
		margin: 2rem 0 0;
	}

	.ScoutingForm-footer {
		margin: 0 0 2rem;
	}

	.ScoutingForm-actions {
		height: 4rem;

		@include large-desktop {
			height: 10rem;
		}
	}

	.ScoutingForm-loading {
		@include font-usual(1.125, $color-black, 400, 2);
	}

	.ScoutingForm-feedback {
		@include font-usual(1.125, $color-black, 400, 2);

		&.ScoutingForm-feedback--error {
			color: $color-red;
		}
	}

	.ScoutingForm-warning {
		margin: 0 0 6rem;

		@include large-desktop {
			margin: 0 0 12rem;
		}

		> div {
			margin: 2rem 0 0;

			@include font-usual(0.75, $color-black, 400, 1.375);
		}
	}
}

