@import "../../shared/styles/index";

.Radio {
	position: relative;

	@include tablet {
		display: flex;
		align-items: center;
	}

	.Radio-buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 1rem 0 0;

		@include tablet {
			margin: 0 0 0 2rem;
			justify-content: flex-start;
		}
	}

	.Radio-button {
		box-sizing: border-box;
		cursor: pointer;
		@include font-usual(0.875, $color-black--pure, 400);
		position: relative;
		text-transform: uppercase;
		transition: all 300ms;
		border: 1px solid $color-black--pure;
		background-color: $color-white;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 2.125rem;
		width: 48%;

		@include tablet {
			width: inherit;
			padding: 0 3rem;
			margin: 0 1rem 0 0;
		}

		&.is-active {
			border-color: transparent;
			color: $color-white;
			background-color: $color-black--pure;
			cursor: default;
		}

		&:disabled {
			pointer-events: none;
			cursor: default;
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}

		&:hover:not(.is-active) {
			opacity: 0.75;
		}

		&:last-child {
			margin: 0;
		}
	}
}
