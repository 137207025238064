@import "../../shared/styles/index";

.WomanList {
	margin: 1rem auto 2.75rem;
	max-width: $max-width-content;
	padding: 0 2rem;

	@include tablet {
		margin: 2rem auto;
	}

	@include xLarge-desktop {
		margin: 2.25rem auto;
		padding: 0;
	}

	.WomanList-items {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		//justify-content: space-between;

		&::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
		overflow: -moz-scrollbars-none;
		-ms-overflow-style: none;
	}

	.WomanList-feedback {
		@include font-usual(1.25, $color-black, 400);
	}
}
