@import "../../shared/styles/index";

.NewsFeed {
	margin: 0 auto 2rem;
	max-width: $max-width-content;
	padding: 0 2rem;
	min-height: 40rem;

	@include xLarge-desktop {
		padding: 0;
	}

	.NewsFeed-title {
		@include font-usual(1.125, $color-black, 400, 2);
		margin: 3rem auto;
		text-transform: uppercase;
		text-align: center;

		a {
			@include font-usual(1.125, $color-black, 700, 2);
			text-decoration: none;
		}

		max-width: 22rem;

		@include tablet {
			font-size: 0.875rem;
			max-width: 100%;

			a {
				font-size: 0.875rem;
			}
		}
	}

	.NewsFeed-items {
		position: relative;
		-webkit-overflow-scrolling: touch;
		scroll-snap-type: y mandatory;
		overflow-y: scroll;
		padding: 2rem 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: $max-width-news;
		margin: 0 auto;

		&::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
		overflow: -moz-scrollbars-none;
		-ms-overflow-style: none;
	}
}
