@import '../../shared/styles/index.scss';

.Video {
	position: relative;
	animation: fadeIn 600ms;
	max-width: 31.875rem;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
	margin: 0 auto;

	@include large-desktop {
		//margin: 0 0.5rem;
		max-width: 40rem;
	}

	@include fullHD-desktop {
		max-width: 50rem;
	}

	.Video-player {
		position: absolute;
		top: 0;
		left: 0;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
