@import "../../shared/styles/index";

.InstagramContainer {
	max-width: $max-width-instagram;
	padding: 0 2rem;
	margin: 3rem auto 5rem;

	@include tablet {
		margin: 4rem auto 7rem;
	}

	@include desktop {
		margin: 8rem auto;
	}

	.InstagramContainer-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 3rem;
	}

	.InstagramContainer-username {
		@include font-usual(0.875, $color-black, 400);
		text-decoration: none;
		transition: opacity 300ms;

		@include large-desktop {
			&:hover {
				opacity: 0.8;
			}
		}
	}

	.InstagramContainer-followers {
		@include font-usual(0.875, $color-black, 400);
	}

	.InstagramContainer-content {
		display: flex;
		flex-wrap: wrap;

		@include desktop {
			//justify-content: space-between;
		}
	}
}

