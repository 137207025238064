@import "../../shared/styles/index";

.ThankYou {
	position: relative;
	animation: fadeIn 1.2s ease-out;
	min-height: 15rem;
	display: flex;
	align-items: center;
	justify-content: center;

	.ThankYou-title {
		@include font-ivymode(3.5, $color-black--pure, 300);
		text-align: center;
		margin: 0 0 10rem;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

