/* Fonts */
$font-primary: 'usual',
Helvetica,
Arial,
sans-serif;

$font-secondary: 'ivymode',
Helvetica,
Arial,
sans-serif;

@mixin font-usual($size: false,
$colour: false,
$weight: false,
$lh: false,
$ls: 0.6
) {
  font-family: $font-primary;

  @if $size {
    font-size: $size + rem;
  }

  @if $colour {
    color: $colour;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh + rem;
  }

  @if $ls {
    letter-spacing: $ls + px;
  }
}

@mixin font-ivymode($size: false,
$colour: false,
$weight: false,
$lh: false,
$ls: 0.6
) {
  font-family: $font-secondary;

  @if $size {
    font-size: $size + rem;
  }

  @if $colour {
    color: $colour;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh + rem;
  }

  @if $ls {
    letter-spacing: $ls + px;
  }
}
