@import "../../shared/styles/index";

.InstagramImage {
	position: relative;
	background-color: $color-gray--dark;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	flex-shrink: 0;
	margin: 0 auto 2rem;
	width: 19.625rem;
	height: 19.625rem;

	@include tablet {
		margin: 0 2rem 2rem 0;
		width: calc(50% - 1rem);

		&:nth-of-type(2n) {
			margin: 0 0 2rem 0;
		}
	}

	@include desktop {
		width: 18.5rem;
		height: 18.5rem;
		margin: 0 2.25rem 2rem 0;

		&:nth-of-type(2n){
			margin: 0 2.25rem 2rem 0;
		}

		&:nth-of-type(3n){
			margin: 0 0 2rem 0;
		}
	}

	@include large-desktop {
		width: 19.625rem;
		height: 19.625rem;
		margin: 0 2.5rem 2rem 0;

		&:nth-of-type(2n){
			margin: 0 2.5rem 2rem 0;
		}

		&:nth-of-type(3n){
			margin: 0 0 2rem 0;
		}
	}
}
