@import "../../shared/styles/index";

.Header {
	box-sizing: border-box;
	width: 100%;
	padding: 2rem;

	&.Header--center {
		.Header-menu {
			margin: 0 auto;

			@include tablet {
				order: 3;
				margin: 0 0 0 auto;
			}
		}
	}

	&.Header--overlay {
		position: absolute;
		top: 0;
		z-index: 2;

	}

	@include tablet {
		padding: 2rem;
	}

	@include xLarge-desktop {
		padding: 2rem 0;
	}

	&.Header--wide {
		padding: 2rem 0;

		.Header-content {
			max-width: 100%;
			padding: 0 1.5rem;

			@include large-desktop {
				padding: 0 5rem;
			}
		}
	}

	.Header-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		max-width: $max-width-content;
		margin: 0 auto;
		box-sizing: border-box;

		@include tablet {
			flex-wrap: nowrap;
			align-items: center;
		}

		> button {
			z-index: 1001;

			@include tablet {
				margin: 0 0 0 auto;
			}
		}
	}

	.Header-menu {
		order: 2;
		z-index: 999;
		//margin: 0 auto;

		@include tablet {
			order: 3;
			margin: 0 0 0 auto;
		}
	}

	.Header-search {
		width: 100%;
		order: 3;
		margin: 1.5rem 0 0;
		display: inline-flex;

		@include tablet {
			margin: 0 auto;
			order: 2;
			max-width: 20rem;
		}

		@include large-desktop {
			max-width: 23.5rem;
		}
	}
}
