@import "../../shared/styles/index";

.DatePicker {
	position: relative;
	border: 1px solid $color-black--pure;
	width: 100%;
	box-sizing: border-box;
	transition: border-color 250ms;

	> input {
		padding: 0 1rem;
		box-sizing: border-box;
		border: 0;
		width: 100%;
		height: 3.375rem;
		@include font-usual(0.875, $color-black, 400);

		@include input-placeholder {
			@include font-usual(0.875, $color-black, 400);
			text-transform: uppercase;
		}

		&:focus {
			border-color: rgba(0, 0, 0, 0.65);
			box-shadow: none;
			outline: 0;
		}
	}
}
