@import "../../shared/styles/index";

.Woman {
	position: relative;
	animation: fadeIn 0.15s ease-out;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
