@import "../../shared/styles/index";

.Picture {
	position: relative;
	transition: transform 250ms, opacity 300ms;
	text-decoration: none;
	z-index: 1;
	flex-shrink: 0;
	height: 35rem;
	width: 17.5rem;
	padding: 0 2rem;
	margin: 0 auto;
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	animation: fadeIn 300ms;

	@include tablet {
		height: 34rem;
		width: 20rem;
	}

	@include desktop {
		height: 32rem;
		width: 18.5rem;
	}

	@include large-desktop {
		height: 33.25rem;
		width: 20rem;
	}

	@include fullHD-desktop {
		height: 42rem;
		width: 28rem;
	}

	&.Picture--full {
		width: auto;
		background-size: contain;
		margin: 0 auto;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
