@import "../../shared/styles/index";

.Button {
	border: 0;
	box-sizing: border-box;
	cursor: pointer;
	@include font-usual(0.875, $color-white, 400);
	position: relative;
	text-transform: uppercase;
	transition: all 300ms;
	background-color: $color-black--pure;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 3.375rem;
	padding: 0 4rem;

	.Button-icon {
		&.Button-icon--left {
			margin: 0 0.5rem 0.05rem 0;
		}
		&.Button-icon--right {
			margin: 0 0 0.05rem 0.5rem;
		}
	}

	&.Button--link {
		color: $color-black;
		padding: 0;
		text-decoration: none;
		height: auto;
		background-color: rgba(255, 255, 255, 0);
		position: relative;

		@include large-desktop {
			&:after {
				background: none repeat scroll 0 0 transparent;
				bottom: -1px;
				content: "";
				display: block;
				right: 0;
				height: 1px;
				position: absolute;
				background: $color-black;
				transition: width 0.3s ease 0s, left 0.3s ease 0s;
				width: 100%;
			}

			&:hover {
				&:after {
					width: 0;
					//left: 50%;
				}
			}
		}
	}

	&.Button--noEffect {
		&:after {
			display: none;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	&.Button--light {
		&.Button--link {
			color: $color-white;

			&:after {
				background-color: $color-white;
			}
		}
	}

	&.is-active {
		font-weight: 600;
	}

	&:disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.6;
	}

	&:focus {
		outline: 0;
		box-shadow: none;
	}

	&:hover {
		text-decoration: none;
	}
}
