@import "../../shared/styles/index";

.Loader {
	position: relative;

	.Loader-image {
		max-width: 3rem;
	}

	&.Loader--large {
		.Loader-image {
			max-width: 8rem;
		}
	}

	&.Loader--center {
		display: flex;
		align-items: center;
		justify-content: center;
		height: calc(100% - 3rem);
	}
}
