@import "../../shared/styles/index";

.Scouting {
	position: relative;
	animation: fadeIn 0.55s ease-out;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

