@import "../../shared/styles/index";

.Footer {
	box-sizing: border-box;
	width: 100%;
	height: 6rem;
	padding: 0 2rem;
	margin: 2rem 0;

	@include desktop {
		margin: 6rem 0 0;
	}

	@include xLarge-desktop {
		padding: 0;
	}

	.Footer-content {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		margin: 0 auto;
		max-width: $max-width-content;

		@include desktop {
			justify-content: space-between;
			flex-flow: row;
		}
	}

	.Footer-navigation {
		display: none;

		@include desktop {
			width: 40%;
			display: inline-flex;
		}
	}

	.Footer-logo {
		max-width: 3rem;
		margin: 0 0 2rem;

		@include desktop {
			width: 20%;
			margin: 0;
		}
	}

	.Footer-instagram {
		text-align: right;
		@include font-usual(0.75, $color-black, 400, 1, 0.5);
		text-transform: uppercase;
		text-decoration: none;
		transition: opacity 300ms;

		@include desktop {
			width: 40%;
		}

		&:hover {
			opacity: 0.5;
		}
	}
}
