@import "../../shared/styles/index";

.WomanFilters {
	display: flex;
	align-items: center;
	margin: 0 0 0 2rem;
	max-width: $max-width-content;
	scroll-snap-type: x mandatory;
	overflow-x: scroll;
	overflow-y: hidden;
	min-height: 3rem;
	box-sizing: border-box;

	@include tablet {
		padding: 0 2rem;
		margin: 1.75rem auto 0;
		overflow-x: hidden;
		min-height: auto;
	}

	@include xLarge-desktop {
		padding: 0;
		margin: 3rem auto 0;
	}

	> button {
		margin: 0 2.5rem 0 0;
		font-size: 0.75rem;
		/* Snap align start. */
		scroll-snap-align: start;
		&:last-child {
			padding: 0 2rem 0 0;
		}

		@include tablet {
			margin: 0 3.5rem 0 0;

			&:last-child {
				padding: 0;
			}
		}
	}
}
