@import '../../shared/styles/index.scss';

.UploadBox {
	flex-shrink: 0;
	width: 100%;
	position: relative;

	max-width: 19rem;
	height: 19rem;

	&:hover {
		.UploadBox-remove {
			opacity: 1;
		}
	}

	@include tablet {
		max-width: 14rem;
		height: 14rem;
	}

	@include desktop {
		max-width: 19rem;
		height: 19rem;
	}

	@include large-desktop {
		max-width: 20.5rem;
		height: 20.5rem;
	}

	.UploadBox-preview {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}

	.UploadBox-content {
		width: 100%;
		height: 100%;
		cursor: pointer;
		border: 1px dashed $color-gray;
		box-sizing: border-box;

		&.is-disabled {
			opacity: .5;
			cursor: not-allowed;
		}

		&.is-active {
			border: 2px dashed $color-red;
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}

	.UploadBox-title {
		margin: 1.25rem;
		display: inline-block;
		@include font-usual(0.875, $color-black, 400);
	}

	.UploadBox-remove {
		position: absolute;
		top: 1.25rem;
		right: 1.25rem;
		z-index: 1;
		opacity: 0;

		@include font-usual(0.875, $color-red, 400);
		cursor: pointer;
		background-color: #FFF;
		border-radius: 0;
		padding: 0.125rem 0.25rem;
		border: 0;
		margin: 0;
		transition: opacity 300ms;

		&:focus {
			outline: 0;
			box-shadow: none;
		}

		&:hover {
			opacity: 0.9;
		}
	}
}
