@import "../../shared/styles/index";

.ModelLightBox {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	background-color: $color-white;
	transition: opacity 0.35s, z-index 0.5s;
	opacity: 0;
	z-index: -1;
	overflow-y: auto;

	@include tablet {
		overflow-y: hidden;
	}

	&.is-visible {
		transition: opacity 0.35s;
		opacity: 1;
		z-index: 999;
	}

	.ModelLightBox-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		z-index: 1;
		padding: 1.5rem 2rem;
		box-sizing: border-box;
		width: 100%;

		> button {
			&:nth-of-type(2) {
				display: none;

				@include desktop {
					display: flex;
				}
			}
		}

		> img {
			display: none;

			@include tablet {
				display: inline-flex;
			}
		}
	}

	.ModelLightBox-content {
		background: $color-white;
		width: 100%;
		display: flex;
		flex-flow: column;
		overflow-y: auto;
		height: auto;

		@include tablet {
			height: 100%;
		}
	}

	.ModelLightBox-logo {
		max-width: 3rem;
	}

	.ModelLightBox-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		z-index: 1;
		padding: 1.5rem 2rem;
		box-sizing: border-box;
		width: 100%;
		margin: auto 0 0;

		@include desktop {
			flex-flow: row;
			justify-content: space-between;
		}
	}

	.ModelLightBox-media {
		@include font-usual(3, $color-black, 400, 1, 0.5);
		display: flex;
		align-items: center;
		flex-flow: column;
		width: 100%;
		box-sizing: border-box;
		margin: auto auto 3rem;
		height: auto;

		@include tablet {
			justify-content: center;
			height: 47rem;
			margin: auto;
		}

		@include desktop {
			flex-flow: row;
			height: 36rem;
		}

		@include fullHD-desktop {
			height: 43rem;
		}

		opacity: 0;
		transition: opacity 3s;
		overflow: hidden;

		&.is-visible {
			opacity: 1;
			transition: opacity .35s;
			z-index: 90;
			overflow-y: auto;
		}
	}

	.ModelLightBox-actions {
		@include font-usual(0.75, $color-black, 400, 1, 0.5);
		z-index: 1;
		margin: 0 0 1rem;
		height: 3rem;

		@include desktop {
			display: flex;
			align-items: center;
			margin: 0;
			height: auto;

			> a {
				margin: 0 2rem 0 0;

				&:last-child {
					margin: 0;
				}
			}
		}
	}

	.ModelLightBox-instagram {
		@include font-usual(0.875, $color-black, 600);
		text-transform: uppercase;
		text-decoration: none;
		transition: all 250ms;

		&:hover {
			opacity: 0.8;
		}
	}

	.ModelLightBox-footerLogo {
		display: flex;
		flex-flow: column;
		margin: auto auto 1rem;
		justify-content: center;
		align-items: center;

		@include desktop {
			display: none;
		}
	}

	.ModelLightBox-triangle {
		max-width: 3rem;
		margin: 0 0 1rem;
	}

	.ModelLightBox-instagram {
		@include font-usual(0.75, $color-black, 400, 1, 0.5);
		text-transform: uppercase;
		text-decoration: none;
		transition: opacity 300ms;

		&:hover {
			opacity: 0.5;
		}
	}
}
