@import "../../shared/styles/index";

.OverlayNav {
	margin: 0 auto;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: $color-white;
	z-index: 100;

	&.is-visible {
		height: 100vh;
	}

	.OverlayNav-close {
		display: none;
	}

	.OverlayNav-toggle {
		position: absolute;
		top: -0.625rem;
		right: 0;
		height: 1.75rem;
		width: 2rem;
		cursor: pointer;
		z-index: 100;
		transition: opacity .25s ease;
		background-color: transparent;
		border: 0;

		&:focus {
			outline: 0;
			box-shadow: none;
		}

		&:hover {
			opacity: .8;
		}

		&.is-active {
			> span {
				&:nth-of-type(1) {
					transform: translateY(0.5rem) translateX(0) rotate(45deg);
					background: $color-black;
				}
				&:nth-of-type(2) {
					opacity: 0;
					background: $color-black;
				}
				&:nth-of-type(3) {
					transform: translateY(-0.5rem) translateX(0) rotate(-45deg);
					background: $color-black;
				}
			}
		}

		span {
			background: $color-black;
			border: none;
			height: 0.175rem;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transition:  all .35s ease;
			cursor: pointer;

			&:nth-of-type(2) {
				top: 0.5rem;
			}

			&:nth-of-type(3) {
				top: 1rem
			}
		}
	}

	.OverlayNav-content {
		//position: fixed;
		background: $color-white;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		opacity: 0;
		transition: opacity .3s, height .35s ease-out;
		overflow: hidden;

		&.is-visible {
			opacity: 1;
			transition: opacity .35s, height .35s;
			height: 100vh;
			z-index: 90;

			li {
				animation: fadeInRight .5s ease forwards;
				animation-delay: .35s;

				&:nth-of-type(2) {
					animation-delay: .4s;
				}
				&:nth-of-type(3) {
					animation-delay: .45s;
				}
				&:nth-of-type(4) {
					animation-delay: .5s;
				}
			}
		}

		.OverlayNav-menu {
			position: relative;
			height: 83%;
			top: 48%;
			transform: translateY(-42%);
			text-align: center;

			@include mobile {
				top: 52%;
			}

			@include tablet {
				height: 83%;
				top: 80%;
				transform: translateY(-70%);
			}

			@include desktop {
				height: 77%;
				top: 72%;
				transform: translateY(-70%);
			}

			@include large-desktop {
				height: 87%;
				top: 87%;
				transform: translateY(-80%);
			}

			@include xLarge-desktop {
				height: 87%;
				top: 87%;
				transform: translateY(-75%);
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0 auto;
			display: inline-block;
			position: relative;
			height: 100%;
		}

		span.OverlayNav-item {
			cursor: default;
		}

		.OverlayNav-wrapper {
			> span {
				cursor: default;
			}

			&:hover {
				.OverlayNav-submenu {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-flow: column;
					height: 10rem;
					opacity: 1;
					transition: height 300ms ease-out, opacity 600ms;

					@include mobile {
						flex-flow: row;
						height: 3rem;
					}

					@include tablet {
						height: 5rem;
						min-width: 30rem;
					}

					> a {
						animation: fadeInBottom .35s ease forwards;
						animation-delay: .35s;
						margin: 0 0 1rem;

						@include mobile {
							margin: 0 1.5rem;
						}

						&:nth-of-type(2) {
							animation-delay: .40s;
						}
						&:nth-of-type(3) {
							animation-delay: .45s;
						}
						&:nth-of-type(4) {
							animation-delay: .5s;
						}
					}
				}
			}
		}

		.OverlayNav-submenu {
			box-sizing: border-box;
			height: 0;
			overflow-y: hidden;
			opacity: 0;
			transition: height 300ms ease-out, opacity 600ms;

			> a {
				opacity: 0;
			}
		}

		.OverlayNav-item {
			display: block;
			position: relative;
			opacity: 0;
			margin: 0 0 1.5rem;

			@include tablet {
				margin: 0 0 2rem;
			}

			@include large-desktop {
				margin: 0 0 2.5rem;
			}

			/*
			&:hover:after,
			&:focus:after,
			&:active:after {
				width: 100%;
			}*/

			/*
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: 0%;
				transform: translateX(-50%);
				height: 3px;
				background: $color-black;
				transition: .35s;
			}*/
		}

		.OverlayNav-link {
			display: block;
			position: relative;
			color: $color-black;
			text-decoration: none;
			overflow: hidden;
			@include font-ivymode(2.875, $color-black, 300);
			text-transform: uppercase;
			transition: opacity 300ms;

			@include tablet {
				font-size: 3.125rem;
			}

			&:hover {
				opacity: 0.5;
			}
		}

		.OverlayNav-footer {
			display: flex;
			flex-flow: column;
			margin: auto auto 1rem;
			justify-content: center;
			align-items: center;
			position: absolute;
			bottom: 6rem;
			left: calc(50% - 4.125rem);

			@include tablet {
				bottom: 5rem;
			}

			@include desktop {
				bottom: 4rem;
			}

			@include large-desktop {
				bottom: 1rem;
			}
		}

		.OverlayNav-logo {
			max-width: 3rem;
			margin: 0 0 1rem;
			display: none;

			@include tablet {
				display: block;
			}
		}

		.OverlayNav-instagram {
			@include font-usual(0.75, $color-black, 400, 1, 0.5);
			text-transform: uppercase;
			text-decoration: none;
			transition: opacity 300ms;

			&:hover {
				opacity: 0.5;
			}
		}
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		left: 20%;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}

@keyframes fadeInBottom {
	0% {
		opacity: 0;
		top: 20%;
	}
	100% {
		opacity: 1;
		top: 0;
	}
}
