@import "../../shared/styles/index";

.ModelCover {
	display: flex;
	width: 100%;
	position: relative;
	min-height: 20rem;

	.ModelCover-logo {
		display: none;

		@include desktop {
			display: inline-flex;
			max-width: 3rem;
			margin: 0 0 2.25rem;
			z-index: 1;
		}
	}

	.ModelCover-content {
		position: absolute;
		top: 30%;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;

		@include tablet {
			top: 35%;
		}

		@include large-desktop {
			top: 30%;
		}

		@include xLarge-desktop {
			top: 35%;
		}
	}

	.ModelCover-name {
		@include font-ivymode(2.75, $color-white, 300, 3.25, 3);
		text-transform: uppercase;
		margin: 0 0 6rem;
		text-align: center;
		max-width: 22.5rem;
		z-index: 1;

		@include tablet {
			font-size: 4.375rem;
			max-width: 100%;
			margin: 0 0 2.5rem;
		}
	}

	.ModelCover-gallery {
		width: 100%;
		position: relative;
	}

	.ModelCover-image {
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
		position: relative;
		height: 50rem;

		@include desktop {
			height: 48rem;
		}

		@include large-desktop {
			height: 43rem;
		}

		@include xLarge-desktop {
			height: 50rem;
		}

		@include fullHD-desktop {
			height: 60rem;
		}
	}

	.ModelCover-overly {
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		@include background-opacity($color-black, 0.2)
	}
}

