@import "../../shared/styles/index";

.Cover {
	box-sizing: border-box;
	margin: 0 auto;
	width: 100%;
	position: relative;

	.Cover-overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include background-opacity($color-black, 0.2);
		z-index: 5;
	}

	.Cover-triangle {
		max-width: 10rem;
		animation: fadeIn 800ms;
		transform: translateY(6rem);
		z-index: 5;

		@include mobile {
			display: none;
		}

		@include tablet {
			max-width: 12.5rem;
			display: block;
		}

		@include desktop {
			transform: translateY(0);
		}
	}

	.Cover-content {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 39.375rem;
		margin: 0 auto;
		background-size: cover;
		background-color: $color-gray--dark;
		background-position: center center;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
