@import '../../shared/styles/index';

.Input {
  width: 100%;
  position: relative;
  background-color: transparent;
  box-sizing: border-box;
  flex-shrink: 0;

  &.Input--underline {
    .Input-control {
      border: 0;
      border-bottom: 1px solid $color-black--pure;
      @include font-usual(0.75, $color-black, 400);
      height: inherit;
      padding: 0 0 0.75rem;

      @include input-placeholder {
        @include font-usual(0.75, $color-black, 400);
        text-transform: lowercase;
      }
    }
  }

  @include tablet {
    max-width: 21.5rem;
  }

  @include desktop {
    max-width: 29.5rem;
  }

  @include large-desktop {
    max-width: 32rem;
  }

  &.Input--small {
    width: 48%;

    @include tablet {
      width: 100%;
    }

    @include large-desktop {
      max-width: 11.875rem;
    }
  }

  &.Input--medium {
    width: 48%;

    @include tablet {
      width: 100%;
    }

    @include large-desktop {
      max-width: 15.375rem;
    }
  }

  &.Input--large {
    max-width: 100%;
    transition: max-width 500ms ease;

    @include tablet {
      max-width: 21.5rem;
    }

    @include desktop {
      max-width: 49%;
    }

    @include large-desktop {
      max-width: 18.5rem;
    }

    &.Input--dynamic {
      &.is-focused {
        max-width: 32.5rem;
      }
    }
  }

  &.Input--error {
    .Input-control {
      color: $color-red;
      border-color: $color-red;
      border-width: 2px;
    }

    &.is-focused {
      .Input-control {
        border-color: $color-red;
      }
    }
  }

  &.is-focused {
    .Input-control {
      border-color: $color-black--pure;
    }
  }

  .Input-control {
    position: relative;
    border: 1px solid $color-black--pure;
    height: 3.375rem;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 250ms;
    padding: 0 1rem;
    @include font-usual(0.875, $color-black, 400);

    @include input-placeholder {
      @include font-usual(0.875, $color-black, 400);
      text-transform: uppercase;
    }

    &:focus {
      border-color: rgba(0, 0, 0, 0.65);
      box-shadow: none;
      outline: 0;
    }

    &.Input-control--capitalize {
      text-transform: capitalize;
    }

    &.Input-control--uppercase {
      text-transform: uppercase;
    }

    &.Input-control--lowercase {
      text-transform: lowercase;
    }
  }
}
