@import "../../shared/styles/index";

.ContactsList {
	margin: 0 0 5rem;

	@include large-desktop {
		margin: 3rem 0;
	}

	.ContactsList-label {
		text-transform: uppercase;
		@include font-ivymode(0.9375, $color-black, 300, 1.75, 1);
	}

	.ContactsList-values {
		@include font-usual(0.9375, $color-black, 400, 1.5);
		text-transform: uppercase;

		a {
			@include font-usual(0.9375, $color-black, 400);
			text-decoration: none;
			transition: opacity 300ms;

			&:hover {
				opacity: 0.5;
			}
		}
	}

	.ContactsList-items {
		position: relative;
		list-style: none;
		margin: 0;
		-webkit-overflow-scrolling: touch;
		scroll-snap-type: y mandatory;
		overflow-y: scroll;
		padding: 2rem 0;

		&::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
		overflow: -moz-scrollbars-none;
		-ms-overflow-style: none;
	}

	.ContactsList-item {
		margin: 0 0 3.5rem;
		flex-shrink: 0;
		scroll-snap-align: center;
		text-align: center;
	}

	.ContactsList-wrapper {
		display: flex;
		align-items: center;
		flex-flow: column;
		width: 100%;
		margin: 0 0 2.5rem;

		&:last-child {
			margin: 0;
		}
	}
}
