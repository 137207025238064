@import "../../shared/styles/index";

.CheckBox {
	display: flex;
	align-items: center;
	margin: 1rem 0;

	.CheckBox-input {
		width: 1.375rem;
		height: 1.375rem;
		border: 1px solid $color-black--pure;
		margin: 0 1.5rem 0 0;
		cursor: pointer;
		background-color: $color-white;
		flex-shrink: 0;

		transition: background-color 250ms;
		&:focus {
			outline: 0;
			box-shadow: none;
		}

		&.is-checked {
			background-color: $color-black--pure;
		}
	}

	.CheckBox-text {
		@include font-usual(0.75, $color-black, 400, 1.25);

		a {
			@include font-usual(0.75, $color-black, 400, 1.25);
		}
	}
}
