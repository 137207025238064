@import '../../shared/styles/index.scss';

.ModelGallery {
	position: relative;
	animation: fadeIn 600ms;
	width: 100%;
	max-width: $max-width-content;
	margin: 0 auto;
	box-sizing: border-box;

	@include tablet {
		padding: 0 1.5rem;
		overflow-y: auto;
		height: 100%;
	}

	@include xLarge-desktop {
		padding: 0;
	}

	.ModalGallery-content {
		position: relative;
		overflow: hidden;
		height: 0;
		max-width: $max-width-lightbox-content;
		margin: 0 auto;
		box-sizing: border-box;
		opacity: 0;
		transition: opacity 800ms;

		@include desktop {
			max-width: 45rem;
		}

		@include large-desktop {
			max-width: 48rem
		}

		@include fullHD-desktop {
			max-width: 61.25rem;
		}

		&.is-visible {
			overflow-y: auto;
			height: 100%;
			opacity: 1;

			@include large-desktop {
				overflow-y: hidden;
			}

			@include xLarge-desktop {
				overflow-y: auto;
			}
		}

		&.is-hidden {
			opacity: 0;
		}
	}

	.ModelGallery-wrapper {
		display: flex;
	}

	.ModelGallery-navigation {
		display: none;

		@include desktop {
			position: absolute;
			left: 0;
			z-index: 100;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			top: 44%;
			padding: 0 6rem;
		}

		@include large-desktop {
			top: 46%;
			padding: 0 8rem;
		}

		@include fullHD-desktop {
			padding: 0 2rem;
		}
	}

	.ModelGallery-button {
		padding: 0;
		margin: 0;
		background-color: transparent;
		border: 0;
		cursor: pointer;
		flex-shrink: 0;
		display: flex;
		align-items: center;

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
